import request from "@/utils/request";

/**
 * @description: 综合查询接口，需要连表查询等聚合查询都在这个里面 单独查询在原来的单独服务中写
 * @param {*} query
 */
export function listWaterUnits(query) {
  return request({
    url: "/api/WaterAggregation/ListWaterUnits",
    method: "get",
    params: query,
  });
}
