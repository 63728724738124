<template>
  <div class="fullPage">
    <!-- navbar -->
    <van-nav-bar
      title="水表列表"
      right-text="筛选"
      @click-right="leftSideFlagChange(true)"
    />

    <!-- 水表内容 -->
    <van-tree-select
      height="calc(100vh - 96px)"
      :items="areas"
      :main-active-index.sync="active"
      @click-nav="change"
    >
      <template #content>
        <div ref="list" style="height: calc(100vh - 96px); overflow-y: auto">
          <van-empty v-if="list.length === 0" description="" />
          <van-list
            v-model="loading"
            :immediate-check="false"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div
              class="item"
              v-for="(item, index) in list"
              :key="index"
              @click="toPage(item.id)"
            >
              <div class="one cell d-flex col-center">
                <div class="name flex-1">
                  <span class="text">{{ item.name }}</span>
                </div>
                <div class="tag d-flex flex-shrink-0 margin-l-20">
                  <van-tag v-if="item.cycleRecord" round type="success"
                    >已抄</van-tag
                  >
                  <van-tag v-else round type="warning">未抄</van-tag>
                  <van-tag
                    v-if="!item.paid"
                    class="margin-l-4"
                    round
                    type="danger"
                    >欠费</van-tag
                  >
                </div>
              </div>
              <div class="margin-l-10">
                <div class="two cell">
                  <div class="address">
                    <van-icon name="location-o" />
                    <span class="text">{{ item.address || "未录入" }}</span>
                  </div>
                </div>
                <div class="cell d-flex phone flex-1">
                  <van-icon name="phone-o" />
                  <span class="text">{{ item.phone || "未录入" }}</span>
                </div>

                <div
                  class="three cell d-flex row-between col-center"
                  v-if="item.remark"
                >
                  <div class="remark">
                    <span class="text-info">{{ item.remark || "未录入" }}</span>
                  </div>
                </div>
                <div
                  class="cell d-flex phone flex-1 align-center padding-t-2"
                  style="
                    border-top: 1px solid #eee;
                    color: #aaa;
                    margin-bottom: 4px;
                  "
                >
                  <span>上次抄表日期：</span>
                  <span
                    class="text"
                    style="color: #aaa; position: relative; top: 2px"
                    >{{ item.lastRecordTime | Date1 }}</span
                  >
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </template>
    </van-tree-select>

    <!-- 筛选弹窗 -->
    <van-popup
      v-model="leftSideFlag"
      position="left"
      :style="{ width: '100%', height: '100%' }"
    >
      <leftSide :close="leftSideFlagChange" :getList="getList"></leftSide>
    </van-popup>
    <!-- tabbar  -->
    <tabbar></tabbar>
  </div>
</template>

<script>
import storage from "@/utils/storage";
import tabbar from "@/components/TabBar";
import { listWaterUnits } from "@/apis/water-aggregation.js";
import Filter from "./components/Filter";
export default {
  name: "PageList",
  components: { leftSide: Filter, tabbar },
  data() {
    return {
      active: 0,
      areas: [],
      loading: false,
      finished: false,
      total: 0,
      list: [],
      leftSideFlag: false,
      value: "",
      params: {
        id: 0,
        SkipCount: 0,
      },
      firstEnter: true,
    };
  },
  created() {
    console.log("created");
    this.getArea();
  },
  activated() {
    console.log("activated");
    if (!this.firstEnter) {
      let params = storage.get("listParams");
      this.getList({
        ...params,
        SkipCount: 0,
        AreaId: this.areas[this.active].id,
      });
      document.querySelector(".van-tree-select__nav").scrollTop =
        this.scrollTop;
    }
    this.firstEnter = false;
  },
  methods: {
    onLoad() {
      this.getList();
      this.firstEnter = false;
    },
    change(index) {
      this.scrollTop = document.querySelector(
        ".van-tree-select__nav"
      ).scrollTop;
      this.active = index;
      let params = storage.get("listParams");
      this.getList({
        ...params,
        SkipCount: 0,
        AreaId: this.areas[index].id,
      });
      this.areaName = this.areas[index].text;
    },
    getList(params) {
      if (!params) {
        this.params.SkipCount = this.list.length || 0;
      } else {
        this.params = params;
        if (params.SkipCount === 0) {
          this.$refs.list.scrollTop = 0;
        }
      }

      storage.set("listParams", this.params);
      this.params.AreaId = this.areas[this.active].id;
      listWaterUnits(this.params).then((res) => {
        if (res.succeeded) {
          if (this.params.SkipCount === 0) {
            this.list = res.data.items;
          } else {
            this.list.push(...res.data.items);
          }
          this.total = res.data.totalCount;

          this.loading = false;
          this.finished = false;
          if (this.list.length >= this.total) {
            this.loading = true;
            this.finished = true;
          }
        }
      });
    },
    getArea() {
      this.$store.dispatch("getAreaList").then((res) => {
        this.areas = res.map((item) => {
          return {
            text: item.name,
            id: item.areaId || item.id,
          };
        });
        this.change(0);
      });
    },
    leftSideFlagChange(flag) {
      this.leftSideFlag = flag;
    },
    toPage(id) {
      this.$router.push({
        path: "/record-detail?id=" + id + "&areaName=" + this.areaName,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.fullPage {
  min-height: 100vh;
  min-width: 100vw;
  background-color: #f6fafd !important;
}
.search {
  // position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}

.item {
  margin: 15px 10px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 5px 5px 1px #eee;

  .one {
    border-bottom: 1px solid #eee;
    background-color: #bdd5e1;
    color: #fff;
    padding: 10px;
  }
  .cell {
    display: flex;
    margin-bottom: 10px;

    .van-icon {
      color: #1989fa;
    }

    .text {
      color: #444;
      margin-left: 4px;
    }
  }
}

.van-tree-select__content {
  flex: 3;
}
</style>

