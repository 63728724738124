<template>
  <div>
    <van-form>
      <!-- 水表名字 -->
      <van-field v-model="query.Name" label="水表名称" placeholder="水表名称" />
      <!-- 周期 -->
      <van-field
        readonly
        clickable
        label="周期"
        :value="value1"
        placeholder="选择周期"
        @click="show1 = true"
      />
      <van-popup v-model="show1" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns1"
          @cancel="show1 = false"
          @confirm="onConfirm1"
        />
      </van-popup>
      <!-- 是否付费 -->
      <van-field
        readonly
        clickable
        label="付费"
        :value="value2"
        placeholder="选择是否付费"
        @click="show2 = true"
      />
      <van-popup v-model="show2" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns2"
          @cancel="show2 = false"
          @confirm="onConfirm2"
        />
      </van-popup>
      <!-- 用水性质 -->
      <van-field
        readonly
        clickable
        label="用水性质"
        :value="value3"
        placeholder="选择用水性质"
        @click="show3 = true"
      />
      <van-popup v-model="show3" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns3"
          @cancel="show3 = false"
          @confirm="onConfirm3"
        />
      </van-popup>
      <!-- 计费方式 -->
      <van-field
        readonly
        clickable
        label="计费方式"
        :value="value4"
        placeholder="选择计费方式"
        @click="show4 = true"
      />
      <van-popup v-model="show4" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns4"
          @cancel="show4 = false"
          @confirm="onConfirm4"
        />
      </van-popup>
      <!-- 按钮 -->
      <div style="margin: 16px">
        <van-button
          round
          block
          type="info"
          native-type="button"
          @click="submitClick"
        >
          提交
        </van-button>
      </div>
      <div style="margin: 16px">
        <van-button
          round
          block
          type="default"
          native-type="button"
          @click="restClick"
        >
          重置
        </van-button>
      </div>
      <div style="margin: 16px">
        <van-button
          round
          block
          type="default"
          native-type="button"
          @click="cancelClick"
        >
          取消
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
// import storage from "@/utils/storage";
import { objFilterEmptyString } from "@/utils/func.js";
import { column1, column2, column3, column4 } from "./config";
export default {
  props: ["close", "getList"],
  data() {
    return {
      value1: "全部",
      show1: false,
      columns1: column1,
      value2: "全部",
      show2: false,
      columns2: column2,
      value3: "全部",
      show3: false,
      columns3: column3,
      value4: "全部",
      show4: false,
      columns4: column4,
      //列表查询参数
      query: {
        Name: "",
        Paid: "",
        BillingCycleType: "", //缴费周期
        BillingType: "", //缴费方式
        UseWaterType: "", //用水性质
      },
    };
  },
  created() {
    // let params = storage.get("listParams");
    // for (let key in this.query) {
    //   if (params[key]) {
    //     this.query[key] = params[key];
    //   }
    // }
  },
  methods: {
    onConfirm1(val) {
      this.query.BillingCycleType = val.id;
      this.value1 = val.text;
      this.show1 = false;
    },
    onConfirm2(val) {
      this.query.Paid = val.id;
      this.value2 = val.text;
      this.show2 = false;
    },
    onConfirm3(val) {
      this.query.UseWaterType = val.id;
      this.value3 = val.text;
      this.show3 = false;
    },
    onConfirm4(val) {
      this.query.BillingType = val.id;
      this.value4 = val.text;
      this.show4 = false;
    },
    //提交
    submitClick() {
      let params = {
        ...this.query,
        SkipCount: 0,
      };
      let obj = objFilterEmptyString(params);
      this.getList(obj);
      this.close(false);
    },
    // 重置
    restClick() {
      this.query = {
        Name: "",
        AreaId: "",
        Paid: "",
        BillingCycleType: "", //缴费周期
        BillingType: "", //缴费方式
        UseWaterType: "", //用水性质
      };
      this.value1 = "全部";
      this.value2 = "全部";
      this.value3 = "全部";
      this.value4 = "全部";
      this.getList({
        SkipCount: 0,
      });
    },
    //取消
    cancelClick() {
      this.close(false);
    },
  },
};
</script>
<style lang="scss" scoped></style>
