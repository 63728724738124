export let column1 = [
  {
    text: "全部",
    id: "",
  },
  {
    text: "全年",
    id: 0,
  },
  {
    text: "半年",
    id: 1,
  },
  {
    text: "季度",
    id: 2,
  },
];
export let column2 = [
  {
    text: "全部",
    id: "",
  },
  {
    text: "付清",
    id: true,
  },
  {
    text: "欠费",
    id: false,
  },
];
export let column3 = [
  {
    text: "全部",
    id: "",
  },
  {
    text: "生活用水",
    id: 0,
  },
  {
    text: "商业用水",
    id: 1,
  },
  {
    text: "综合用水",
    id: 2,
  },
  {
    text: "定额用水",
    id: 3,
  },
  {
    text: "特殊用水",
    id: 4,
  },
];
export let column4 = [
  {
    text: "全部",
    id: "",
  },
  {
    text: "用量计费",
    id: 0,
  },
  {
    text: "定额计费",
    id: 1,
  },
];
