<template>
  <van-tabbar route safe-area-inset-bottom placeholder>
    <van-tabbar-item replace to="/message" icon="chat-o">
      消息
    </van-tabbar-item>
    <van-tabbar-item replace to="/list" icon="home-o"> 水表 </van-tabbar-item>
    <van-tabbar-item replace to="/my" icon="friends-o"> 我的 </van-tabbar-item>
  </van-tabbar>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
